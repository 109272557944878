import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <a id="spell-list"></a><h2>Spell List</h2>
    <a id="cleric-spells"></a><h3>CLERIC SPELLS</h3>
    <table border="0" cellPadding="0" cellSpacing="0" style={{
      "width": "100%"
    }}>
      <tbody>
        <tr>
          <td></td>
        </tr>
      </tbody>
    </table>
    <h5><a id="zero-level-cleric-spells"></a><a id="orisons"></a><a id="zero"></a>0-LEVEL CLERIC SPELLS (ORISONS)</h5>
    <table className="spell-list" border="0" cellPadding="1" cellSpacing="0" style={{
      "width": "75%"
    }}>
      <tbody>
        <tr>
          <th style={{
            "width": "25%"
          }}>Spell Name</th>
          <th style={{
            "width": "75%"
          }}>Brief Description</th>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsC.html#create-water">Create Water</a>:</td>
          <td> Creates 2 gallons/level of pure water.</td>
        </tr>
        <tr>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsC.html#cure-minor-wounds">Cure Minor
Wounds</a>:</td>
          <td> Cures 1 point of damage.</td>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsDtoE.html#detect-magic">Detect Magic</a>:</td>
          <td> Detects spells and magic items within 60 ft.</td>
        </tr>
        <tr>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsDtoE.html#detect-poison">Detect Poison</a>:</td>
          <td> Detects poison in one creature or object.</td>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsFtoG.html#guidance">Guidance</a>:</td>
          <td> +1 on one attack roll, saving throw, or skill check.</td>
        </tr>
        <tr>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsHtoL.html#inflict-minor-wounds">Inflict
Minor Wounds</a>:</td>
          <td> Touch attack, 1 point of damage.</td>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsHtoL.html#light">Light</a>:</td>
          <td> Object shines like a torch.</td>
        </tr>
        <tr>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsMtoO.html#mending">Mending</a>:</td>
          <td> Makes minor repairs on an object.</td>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsPtoR.html#purify-food-and-drink">Purify
Food and Drink</a>:</td>
          <td> Purifies 1 cu. ft./level of food or water.</td>
        </tr>
        <tr>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsPtoR.html#read-magic">Read Magic</a>:</td>
          <td> Read scrolls and spellbooks.</td>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsPtoR.html#resistance">Resistance</a>:</td>
          <td> Subject gains +1 on saving throws.</td>
        </tr>
        <tr>
          <td className="last-row"><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsTtoZ.html#virtue">Virtue</a>:</td>
          <td className="last-row"> Subject gains 1 temporary hp.</td>
        </tr>
      </tbody>
    </table>
    <a id="first-level-cleric-spells"></a><h5><a id="first"></a>1ST-LEVEL CLERIC SPELLS</h5>
    <table className="spell-list" border="0" cellPadding="1" cellSpacing="0" style={{
      "width": "75%"
    }}>
      <tbody>
        <tr>
          <th style={{
            "width": "25%"
          }}>Spell Name</th>
          <th style={{
            "width": "75%"
          }}>Brief Description</th>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsAtoB.html#bane">Bane</a>:</td>
          <td> Enemies take &ndash;1 on attack rolls and saves
against fear.</td>
        </tr>
        <tr>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsAtoB.html#bless">Bless</a>:</td>
          <td> Allies gain +1 on attack rolls and saves against fear.</td>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsAtoB.html#bless-water">Bless Water</a><sup>
M</sup>:</td>
          <td> Makes holy water.</td>
        </tr>
        <tr>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsC.html#cause-fear">Cause Fear</a>:</td>
          <td> One creature of 5 HD or less flees for 1d4 rounds.</td>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsC.html#command">Command</a>:</td>
          <td> One subject obeys selected command for 1 round.</td>
        </tr>
        <tr>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsC.html#comprehend-languages">Comprehend
Languages</a>:</td>
          <td> You understand all spoken and written languages.</td>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsC.html#cure-light-wounds">Cure Light
Wounds</a>:</td>
          <td> Cures 1d8 damage +1/level (max +5).</td>
        </tr>
        <tr>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsC.html#curse-water">Curse Water</a><sup>
M</sup>:</td>
          <td> Makes unholy water.</td>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsDtoE.html#deathwatch">Deathwatch</a>:</td>
          <td> Reveals how near death subjects within 30 ft. are.</td>
        </tr>
        <tr>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsDtoE.html#detect-chaos">Detect Chaos</a>/<a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsDtoE.html#detect-evil">Evil</a>/<a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsDtoE.html#detect-good">Good</a>/<a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsDtoE.html#detect-law">Law</a>:</td>
          <td> Reveals creatures, spells, or objects of selected
alignment.</td>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsDtoE.html#detect-undead">Detect Undead</a>:</td>
          <td> Reveals undead within 60 ft.</td>
        </tr>
        <tr>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsDtoE.html#divine-favor">Divine Favor</a>:</td>
          <td> You gain +1 per three levels on attack and damage
rolls.</td>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsDtoE.html#doom">Doom</a>:</td>
          <td> One subject takes &ndash;2 on attack rolls, damage
rolls,
saves, and checks.</td>
        </tr>
        <tr>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsDtoE.html#endure-elements">Endure
Elements</a>:</td>
          <td> Exist comfortably in hot or cold environments.</td>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsDtoE.html#entropic-shield">Entropic
Shield</a>:</td>
          <td> Ranged attacks against you have 20% miss chance.</td>
        </tr>
        <tr>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsHtoL.html#hide-from-undead">Hide from
Undead</a>:</td>
          <td> Undead can&rsquo;t perceive one subject/level.</td>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsHtoL.html#inflict-light-wounds">Inflict
Light Wounds</a>:</td>
          <td> Touch deals 1d8 damage +1/level (max +5).</td>
        </tr>
        <tr>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsMtoO.html#magic-stone">Magic Stone</a>:</td>
          <td> Three stones gain +1 on attack, deal 1d6 +1 damage.</td>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsMtoO.html#magic-weapon">Magic Weapon</a>:</td>
          <td> Weapon gains +1 bonus.</td>
        </tr>
        <tr>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsMtoO.html#obscuring-mist">Obscuring Mist</a>:</td>
          <td> Fog surrounds you.</td>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsPtoR.html#protection-from-chaos/evil/good/law">Protection
from Chaos/Evil/Good/Law</a>:</td>
          <td> +2 to AC and saves, counter mind control, hedge out
elementals and outsiders.</td>
        </tr>
        <tr>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsPtoR.html#remove-fear">Remove Fear</a>:</td>
          <td> Suppresses fear or gives +4 on saves against fear for
one subject + one per four levels.</td>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsS.html#sanctuary">Sanctuary</a>:</td>
          <td> Opponents can&rsquo;t attack you, and you can&rsquo;t
attack.</td>
        </tr>
        <tr>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsS.html#shield-of-faith">Shield of Faith</a>:</td>
          <td> Aura grants +2 or higher deflection bonus.</td>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsS.html#summon-monster-i">Summon Monster I</a>:</td>
          <td> Calls extraplanar creature to fight for you.</td>
        </tr>
      </tbody>
    </table>
    <a id="second-level-cleric-spells"></a><h5><a id="second"></a>2ND-LEVEL CLERIC SPELLS</h5>
    <table className="spell-list" border="0" cellPadding="1" cellSpacing="0" style={{
      "width": "75%"
    }}>
      <tbody>
        <tr>
          <th style={{
            "width": "25%"
          }}>Spell Name</th>
          <th style={{
            "width": "75%"
          }}>Brief Description</th>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsAtoB.html#aid">Aid</a>:</td>
          <td> +1 on attack rolls and saves against fear, 1d8
temporary hp +1/level (max +10).</td>
        </tr>
        <tr>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsAtoB.html#align-weapon">Align Weapon</a>:</td>
          <td> Weapon becomes good, evil, lawful, or chaotic.</td>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsAtoB.html#augury">Augury</a><sup> M F</sup>:</td>
          <td> Learns whether an action will be good or bad.</td>
        </tr>
        <tr>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsAtoB.html#bears-endurance">Bear&rsquo;s
Endurance</a>:</td>
          <td> Subject gains +4 to Con for 1 min./level.</td>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsAtoB.html#bulls-strength">Bull&rsquo;s
Strength</a>:</td>
          <td> Subject gains +4 to Str for 1 min./level.</td>
        </tr>
        <tr>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsC.html#calm-emotions">Calm Emotions</a>:</td>
          <td> Calms creatures, negating emotion effects.</td>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsC.html#consecrate">Consecrate</a><sup> M</sup>:</td>
          <td> Fills area with positive energy, making undead weaker.</td>
        </tr>
        <tr>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsC.html#cure-moderate-wounds">Cure
Moderate Wounds</a>:</td>
          <td> Cures 2d8 damage +1/level (max +10).</td>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsDtoE.html#darkness">Darkness</a>:</td>
          <td> 20-ft. radius of supernatural shadow.</td>
        </tr>
        <tr>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsDtoE.html#death-knell">Death Knell</a>:</td>
          <td> Kills <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="abilitiesAndConditions.html#dying">dying</a> creature; you gain 1d8
temporary hp, +2 to Str, and +1 level.</td>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsDtoE.html#delay-poison">Delay Poison</a>:</td>
          <td> Stops poison from harming subject for 1 hour/level.</td>
        </tr>
        <tr>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsDtoE.html#desecrate">Desecrate</a><sup> M</sup>:</td>
          <td> Fills area with negative energy, making undead
stronger.</td>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsDtoE.html#eagles-splendor">Eagle&rsquo;s
Splendor</a>:</td>
          <td> Subject gains +4 to Cha for 1 min./level.</td>
        </tr>
        <tr>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsDtoE.html#enthrall">Enthrall</a>:</td>
          <td> Captivates all within 100 ft. + 10 ft./level.</td>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsFtoG.html#find-traps">Find Traps</a>:</td>
          <td> Notice traps as a rogue does.</td>
        </tr>
        <tr>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsFtoG.html#gentle-repose">Gentle Repose</a>:</td>
          <td> Preserves one corpse.</td>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsHtoL.html#hold-person">Hold Person</a>:</td>
          <td> Paralyzes one humanoid for 1 round/level.</td>
        </tr>
        <tr>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsHtoL.html#inflict-moderate-wounds">Inflict
Moderate Wounds</a>:</td>
          <td> Touch attack, 2d8 damage +1/level (max +10).</td>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsMtoO.html#make-whole">Make Whole</a>:</td>
          <td> Repairs an object.</td>
        </tr>
        <tr>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsMtoO.html#owls-wisdom">Owl&rsquo;s Wisdom</a>:</td>
          <td> Subject gains +4 to Wis for 1 min./level.</td>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsPtoR.html#remove-paralysis">Remove
Paralysis</a>:</td>
          <td> Frees one or more creatures from paralysis or slow
effect.</td>
        </tr>
        <tr>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsPtoR.html#resist-energy">Resist Energy</a>:</td>
          <td> Ignores 10 (or more) points of damage/attack from
specified energy type.</td>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsPtoR.html#lesser-restoration">Restoration,
Lesser</a>:</td>
          <td> Dispels magical ability penalty or repairs 1d4 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="abilitiesAndConditions.html#ability-damaged">ability damage</a>.</td>
        </tr>
        <tr>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsS.html#shatter">Shatter</a>:</td>
          <td> Sonic vibration damages objects or crystalline
creatures.</td>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsS.html#shield-other">Shield Other</a><sup>
F</sup>:</td>
          <td> You take half of subject&rsquo;s damage.</td>
        </tr>
        <tr>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsS.html#silence">Silence</a>:</td>
          <td> Negates sound in 15-ft. radius.</td>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsS.html#sound-burst">Sound Burst</a>:</td>
          <td> Deals 1d8 sonic damage to subjects; may stun them.</td>
        </tr>
        <tr>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsS.html#spiritual-weapon">Spiritual Weapon</a>:</td>
          <td> Magic weapon attacks on its own.</td>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsS.html#status">Status</a>:</td>
          <td> Monitors condition, position of allies.</td>
        </tr>
        <tr>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsS.html#summon-monster-ii">Summon Monster
II</a>:</td>
          <td> Calls extraplanar creature to fight for you.</td>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsTtoZ.html#undetectable-alignment">Undetectable
Alignment</a>:</td>
          <td> Conceals alignment for 24 hours.</td>
        </tr>
        <tr>
          <td className="last-row"><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsTtoZ.html#zone-of-truth">Zone
of Truth</a>:</td>
          <td className="last-row"> Subjects within range cannot lie.</td>
        </tr>
      </tbody>
    </table>
    <a id="third-level-cleric-spells"></a><h5><a id="third"></a>3RD-LEVEL CLERIC SPELLS</h5>
    <table className="spell-list" border="0" cellPadding="1" cellSpacing="0" style={{
      "width": "75%"
    }}>
      <tbody>
        <tr>
          <th style={{
            "width": "25%"
          }}>Spell Name</th>
          <th style={{
            "width": "75%"
          }}>Brief Description</th>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsAtoB.html#animate-dead">Animate Dead</a><sup>
M</sup>:</td>
          <td> Creates undead skeletons and zombies.</td>
        </tr>
        <tr>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsAtoB.html#bestow-curse">Bestow Curse</a>:</td>
          <td> &ndash;6 to an ability score; &ndash;4 on attack
rolls, saves, and
checks; or 50% chance of losing each action.</td>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsAtoB.html#blindness/deafness">Blindness/Deafness</a>:</td>
          <td> Makes subject <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="abilitiesAndConditions.html#blinded">blinded</a> or <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="abilitiesAndConditions.html#deafened">deafened</a>.</td>
        </tr>
        <tr>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsC.html#contagion">Contagion</a>:</td>
          <td> Infects subject with chosen disease.</td>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsC.html#continual-flame">Continual Flame</a><sup>
M</sup>:</td>
          <td> Makes a permanent, heatless torch.</td>
        </tr>
        <tr>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsC.html#create-food-and-water">Create
Food and Water</a>:</td>
          <td> Feeds three humans (or one horse)/level.</td>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsC.html#cure-serious-wounds">Cure Serious
Wounds</a>:</td>
          <td> Cures 3d8 damage +1/level (max +15).</td>
        </tr>
        <tr>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsDtoE.html#daylight">Daylight</a>:</td>
          <td> 60-ft. radius of bright light.</td>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsDtoE.html#deeper-darkness">Deeper
Darkness</a>:</td>
          <td> Object sheds supernatural shadow in 60-ft. radius.</td>
        </tr>
        <tr>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsDtoE.html#dispel-magic">Dispel Magic</a>:</td>
          <td> Cancels spells and magical effects.</td>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsFtoG.html#glyph-of-warding">Glyph of
Warding</a><sup> M</sup>:</td>
          <td> Inscription harms those who pass it.</td>
        </tr>
        <tr>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsHtoL.html#helping-hand">Helping Hand</a>:</td>
          <td> Ghostly hand leads subject to you.</td>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsHtoL.html#inflict-serious-wounds">Inflict
Serious Wounds</a>:</td>
          <td> Touch attack, 3d8 damage +1/level (max +15).</td>
        </tr>
        <tr>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsHtoL.html#invisibility-purge">Invisibility
Purge</a>:</td>
          <td> Dispels invisibility within 5 ft./level.</td>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsHtoL.html#locate-object">Locate Object</a>:</td>
          <td> Senses direction toward object (specific or type).</td>
        </tr>
        <tr>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsMtoO.html#magic-circle-against-chaos/evil/good/law">Magic
Circle against Chaos/Evil/Good/Law</a>:</td>
          <td> As protection spells, but 10-ft. radius and 10
min./level.</td>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsMtoO.html#magic-vestment">Magic Vestment</a>:</td>
          <td> Armor or shield gains +1 enhancement per four levels.</td>
        </tr>
        <tr>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsMtoO.html#meld-into-stone">Meld into
Stone</a>:</td>
          <td> You and your gear merge with stone.</td>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsMtoO.html#obscure-object">Obscure Object</a>:</td>
          <td> Masks object against scrying.</td>
        </tr>
        <tr>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsPtoR.html#prayer">Prayer</a>:</td>
          <td> Allies +1 bonus on most rolls, enemies &ndash;1
penalty.</td>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsPtoR.html#protection-from-energy">Protection
from Energy</a>:</td>
          <td> Absorb 12 points/level of damage from one kind of
energy.</td>
        </tr>
        <tr>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsPtoR.html#remove-blindness/deafness">Remove
Blindness/Deafness</a>:</td>
          <td> Cures normal or magical conditions.</td>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsPtoR.html#remove-curse">Remove Curse</a>:</td>
          <td> Frees object or person from curse.</td>
        </tr>
        <tr>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsPtoR.html#remove-disease">Remove Disease</a>:</td>
          <td> Cures all diseases affecting subject.</td>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsS.html#searing-light">Searing Light</a>:</td>
          <td> Ray deals 1d8/two levels damage, more against undead.</td>
        </tr>
        <tr>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsS.html#speak-with-dead">Speak with Dead</a>:</td>
          <td> Corpse answers one question/two levels.</td>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsS.html#stone-shape">Stone Shape</a>:</td>
          <td> Sculpts stone into any shape.</td>
        </tr>
        <tr>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsS.html#summon-monster-iii">Summon
Monster III</a>:</td>
          <td> Calls extraplanar creature to fight for you.</td>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsTtoZ.html#water-breathing">Water
Breathing</a>:</td>
          <td> Subjects can breathe underwater.</td>
        </tr>
        <tr>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsTtoZ.html#water-walk">Water Walk</a>:</td>
          <td> Subject treads on water as if solid.</td>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsTtoZ.html#wind-wall">Wind Wall</a>:</td>
          <td> Deflects arrows, smaller creatures, and gases.</td>
        </tr>
      </tbody>
    </table>
    <a id="fourth-level-cleric-spells"></a><h5><a id="fourth"></a>4TH-LEVEL CLERIC SPELLS</h5>
    <table className="spell-list" border="0" cellPadding="1" cellSpacing="0" style={{
      "width": "75%"
    }}>
      <tbody>
        <tr>
          <th style={{
            "width": "25%"
          }}>Spell Name</th>
          <th style={{
            "width": "75%"
          }}>Brief Description</th>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsAtoB.html#air-walk">Air Walk</a>:</td>
          <td> Subject treads on air as if solid (climb at 45-degree
angle).</td>
        </tr>
        <tr>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsC.html#control-water">Control Water</a>:</td>
          <td> Raises or lowers bodies of water.</td>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsC.html#cure-critical-wounds">Cure
Critical Wounds</a>:</td>
          <td> Cures 4d8 damage +1/level (max +20).</td>
        </tr>
        <tr>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsDtoE.html#death-ward">Death Ward</a>:</td>
          <td> Grants immunity to death spells and negative energy
effects.</td>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsDtoE.html#dimensional-anchor">Dimensional
Anchor</a>:</td>
          <td> Bars extradimensional movement.</td>
        </tr>
        <tr>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsDtoE.html#discern-lies">Discern Lies</a>:</td>
          <td> Reveals deliberate falsehoods.</td>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsDtoE.html#dismissal">Dismissal</a>:</td>
          <td> Forces a creature to return to native plane.</td>
        </tr>
        <tr>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsDtoE.html#divination">Divination</a><sup>
M</sup>:</td>
          <td> Provides useful advice for specific proposed actions.</td>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsDtoE.html#divine-power">Divine Power</a>:</td>
          <td> You gain attack bonus, +6 to Str, and 1 hp/level.</td>
        </tr>
        <tr>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsFtoG.html#freedom-of-movement">Freedom
of Movement</a>:</td>
          <td> Subject moves normally despite impediments.</td>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsFtoG.html#giant-vermin">Giant Vermin</a>:</td>
          <td> Turns centipedes, scorpions, or spiders into giant
vermin.</td>
        </tr>
        <tr>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsHtoL.html#imbue-with-spell-ability">Imbue
with Spell Ability</a>:</td>
          <td> Transfer spells to subject.</td>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsHtoL.html#inflict-critical-wounds">Inflict
Critical Wounds</a>:</td>
          <td> Touch attack, 4d8 damage +1/level (max +20).</td>
        </tr>
        <tr>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsMtoO.html#greater-magic-weapon">Magic
Weapon, Greater</a>:</td>
          <td> +1 bonus/four levels (max +5).</td>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsMtoO.html#neutralize-poison">Neutralize
Poison</a>:</td>
          <td> Immunizes subject against poison, detoxifies venom in
or on subject.</td>
        </tr>
        <tr>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsPtoR.html#lesser-planar-ally">Planar
Ally, Lesser</a> <sup>X</sup>:</td>
          <td> Exchange services with a 6 HD extraplanar creature.</td>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsPtoR.html#poison">Poison</a>:</td>
          <td> Touch deals 1d10 Con damage, repeats in 1 min.</td>
        </tr>
        <tr>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsPtoR.html#repel-vermin">Repel Vermin</a>:</td>
          <td> Insects, spiders, and other vermin stay 10 ft. away.</td>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsPtoR.html#restoration">Restoration</a><sup>
M</sup>:</td>
          <td> Restores level and ability score <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="abilitiesAndConditions.html#ability-drained">drains</a>.</td>
        </tr>
        <tr>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsS.html#sending">Sending</a>:</td>
          <td> Delivers short message anywhere, instantly.</td>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsS.html#spell-immunity">Spell Immunity</a>:</td>
          <td> Subject is immune to one spell per four levels.</td>
        </tr>
        <tr>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsS.html#summon-monster-iv">Summon Monster
IV</a>:</td>
          <td> Calls extraplanar creature to fight for you.</td>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsTtoZ.html#tongues">Tongues</a>:</td>
          <td> Speak any language.</td>
        </tr>
      </tbody>
    </table>
    <a id="fifth-level-cleric-spells"></a><h5><a id="fifth"></a>5TH-LEVEL CLERIC SPELLS</h5>
    <table className="spell-list" border="0" cellPadding="1" cellSpacing="0" style={{
      "width": "75%"
    }}>
      <tbody>
        <tr>
          <th style={{
            "width": "25%"
          }}>Spell Name</th>
          <th style={{
            "width": "75%"
          }}>Brief Description</th>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsAtoB.html#atonement">Atonement</a> <sup>F
X</sup>:</td>
          <td> Removes burden of misdeeds from subject.</td>
        </tr>
        <tr>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsAtoB.html#break-enchantment">Break
Enchantment</a>:</td>
          <td> Frees subjects from enchantments, alterations, curses,
and petrification.</td>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsC.html#greater-command">Command, Greater</a>:</td>
          <td> As command, but affects one subject/level.</td>
        </tr>
        <tr>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsC.html#commune">Commune</a> <sup>X</sup>:</td>
          <td> Deity answers one yes-or-no question/level.</td>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsC.html#mass-cure-light-wounds">Cure
Light Wounds, Mass</a>:</td>
          <td> Cures 1d8 damage +1/level for many creatures.</td>
        </tr>
        <tr>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsDtoE.html#dispel-chaos">Dispel Chaos</a>/<a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsDtoE.html#dispel-evil">Evil</a>/<a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsDtoE.html#dispel-good">Good</a>/<a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsDtoE.html#dispel-law">Law</a>:</td>
          <td> +4 bonus against attacks.</td>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsDtoE.html#disrupting-weapon">Disrupting
Weapon</a>:</td>
          <td> Melee weapon destroys undead.</td>
        </tr>
        <tr>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsFtoG.html#flame-strike">Flame Strike</a>:</td>
          <td> Smite foes with divine fire (1d6/level damage).</td>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsHtoL.html#hallow">Hallow</a><sup> M</sup>:</td>
          <td> Designates location as holy.</td>
        </tr>
        <tr>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsHtoL.html#mass-inflict-light-wounds">Inflict
Light Wounds, Mass</a>:</td>
          <td> Deals 1d8 damage +1/level to many creatures.</td>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsHtoL.html#insect-plague">Insect Plague</a>:</td>
          <td> Locust swarms attack creatures.</td>
        </tr>
        <tr>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsMtoO.html#mark-of-justice">Mark of
Justice</a>:</td>
          <td> Designates action that will trigger curse on subject.</td>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsPtoR.html#plane-shift">Plane Shift</a><sup>
F</sup>:</td>
          <td> As many as eight subjects travel to another plane.</td>
        </tr>
        <tr>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsPtoR.html#raise-dead">Raise Dead</a><sup>
M</sup>:</td>
          <td> Restores life to subject who died as long as one
day/level ago.</td>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsPtoR.html#righteous-might">Righteous
Might</a>:</td>
          <td> Your size increases, and you gain combat bonuses.</td>
        </tr>
        <tr>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsS.html#scrying">Scrying</a><sup> F</sup>:</td>
          <td> Spies on subject from a distance.</td>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsS.html#slay-living">Slay Living</a>:</td>
          <td> Touch attack kills subject.</td>
        </tr>
        <tr>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsS.html#spell-resistance">Spell Resistance</a>:</td>
          <td> Subject gains SR 12 + level.</td>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsS.html#summon-monster-v">Summon Monster V</a>:</td>
          <td> Calls extraplanar creature to fight for you.</td>
        </tr>
        <tr>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsS.html#symbol-of-pain">Symbol of Pain</a><sup>
M</sup>:</td>
          <td> Triggered rune wracks nearby creatures with pain.</td>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsS.html#symbol-of-sleep">Symbol of Sleep</a><sup>
M</sup>:</td>
          <td> Triggered rune puts nearby creatures into catatonic
slumber.</td>
        </tr>
        <tr>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsTtoZ.html#true-seeing">True Seeing</a><sup>
M</sup>:</td>
          <td> Lets you see all things as they really are.</td>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsTtoZ.html#unhallow">Unhallow</a><sup> M</sup>:</td>
          <td> Designates location as unholy.</td>
        </tr>
        <tr>
          <td className="last-row"><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsTtoZ.html#wall-of-stone">Wall
of Stone</a>:</td>
          <td className="last-row"> Creates a stone wall that can be
shaped.</td>
        </tr>
      </tbody>
    </table>
    <a id="sixth-level-cleric-spells"></a><h5><a id="sixth"></a>6TH-LEVEL CLERIC SPELLS</h5>
    <table className="spell-list" border="0" cellPadding="1" cellSpacing="0" style={{
      "width": "75%"
    }}>
      <tbody>
        <tr>
          <th style={{
            "width": "25%"
          }}>Spell Name</th>
          <th style={{
            "width": "75%"
          }}>Brief Description</th>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsAtoB.html#animate-objects">Animate
Objects</a>:</td>
          <td> Objects attack your foes.</td>
        </tr>
        <tr>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsAtoB.html#antilife-shell">Antilife Shell</a>:</td>
          <td> 10-ft. field hedges out living creatures.</td>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsAtoB.html#banishment">Banishment</a>:</td>
          <td> Banishes 2 HD/level of extraplanar creatures.</td>
        </tr>
        <tr>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsAtoB.html#mass-bears-endurance">Bear&rsquo;s
Endurance, Mass</a>:</td>
          <td> As bear&rsquo;s endurance, affects one subject/ level.</td>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsAtoB.html#blade-barrier">Blade Barrier</a>:</td>
          <td> Wall of blades deals 1d6/level damage.</td>
        </tr>
        <tr>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsAtoB.html#mass-bulls-strength">Bull&rsquo;s
Strength, Mass</a>:</td>
          <td> As bull&rsquo;s strength, affects one subject/level.</td>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsC.html#create-undead">Create Undead</a>:</td>
          <td> Create ghouls, ghasts, mummies, or mohrgs.</td>
        </tr>
        <tr>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsC.html#mass-cure-moderate-wounds">Cure
Moderate Wounds, Mass</a>:</td>
          <td> Cures 2d8 damage +1/level for many creatures.</td>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsDtoE.html#greater-dispel-magic">Dispel
Magic, Greater</a>:</td>
          <td> As dispel magic, but up to +20 on check.</td>
        </tr>
        <tr>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsDtoE.html#mass-eagles-splendor">Eagle&rsquo;s
Splendor, Mass</a>:</td>
          <td> As eagle&rsquo;s splendor, affects one subject/level.</td>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsFtoG.html#find-the-path">Find the Path</a>:</td>
          <td> Shows most direct way to a location.</td>
        </tr>
        <tr>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsFtoG.html#forbiddance">Forbiddance</a><sup>
M</sup>:</td>
          <td> Blocks planar travel, damages creatures of different
alignment.</td>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsFtoG.html#geas/quest">Geas/Quest</a>:</td>
          <td> As lesser geas, plus it affects any creature.</td>
        </tr>
        <tr>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsFtoG.html#greater-glyph-of-warding">Glyph
of Warding, Greater</a>:</td>
          <td> As glyph of warding, but up to 10d8 damage or
6th-level spell.</td>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsHtoL.html#harm">Harm</a>:</td>
          <td> Deals 10 points/level damage to target.</td>
        </tr>
        <tr>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsHtoL.html#heal">Heal</a>:</td>
          <td> Cures 10 points/level of damage, all diseases and
mental conditions.</td>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsHtoL.html#heroes-feast">Heroes&rsquo;
Feast</a>:</td>
          <td> Food for one creature/level cures and grants combat
bonuses.</td>
        </tr>
        <tr>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsHtoL.html#mass-inflict-moderate-wounds">Inflict
Moderate Wounds, Mass</a>:</td>
          <td> Deals 2d8 damage +1/level to many creatures.</td>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsMtoO.html#mass-owls-wisdom">Owl&rsquo;s
Wisdom, Mass</a>:</td>
          <td> As owl&rsquo;s wisdom, affects one subject/level.</td>
        </tr>
        <tr>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsPtoR.html#planar-ally">Planar Ally</a> <sup>X</sup>:</td>
          <td> As lesser planar ally, but up to 12 HD.</td>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsS.html#summon-monster-vi">Summon Monster
VI</a>:</td>
          <td> Calls extraplanar creature to fight for you.</td>
        </tr>
        <tr>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsS.html#symbol-of-fear">Symbol of Fear</a><sup>
M</sup>:</td>
          <td> Triggered rune panics nearby creatures.</td>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsS.html#symbol-of-persuasion">Symbol of
Persuasion</a><sup> M</sup>:</td>
          <td> Triggered rune charms nearby creatures.</td>
        </tr>
        <tr>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsTtoZ.html#undeath-to-death">Undeath to
Death</a><sup> M</sup>:</td>
          <td> Destroys 1d4 HD/level undead (max 20d4).</td>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsTtoZ.html#wind-walk">Wind Walk</a>:</td>
          <td> You and your allies turn vaporous and travel fast.</td>
        </tr>
        <tr>
          <td className="last-row"><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsTtoZ.html#word-of-recall">Word of Recall</a>:</td>
          <td className="last-row"> Teleports you back to designated
place.</td>
        </tr>
      </tbody>
    </table>
    <a id="seventh-level-cleric-spells"></a><h5><a id="seventh"></a>7TH-LEVEL CLERIC SPELLS</h5>
    <table className="spell-list" border="0" cellPadding="1" cellSpacing="0" style={{
      "width": "75%"
    }}>
      <tbody>
        <tr>
          <th style={{
            "width": "25%"
          }}>Spell Name</th>
          <th style={{
            "width": "75%"
          }}>Brief Description</th>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsAtoB.html#blasphemy">Blasphemy</a>:</td>
          <td> Kills, paralyzes, weakens, or dazes nonevil subjects.</td>
        </tr>
        <tr>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsC.html#control-weather">Control Weather</a>:</td>
          <td> Changes weather in local area.</td>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsC.html#mass-cure-serious-wounds">Cure
Serious Wounds, Mass</a>:</td>
          <td> Cures 3d8 damage +1/level for many creatures.</td>
        </tr>
        <tr>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsDtoE.html#destruction">Destruction</a><sup>
F</sup>:</td>
          <td> Kills subject and destroys remains.</td>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsDtoE.html#dictum">Dictum</a>:</td>
          <td> Kills, paralyzes, slows, or deafens nonlawful subjects.</td>
        </tr>
        <tr>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsDtoE.html#ethereal-jaunt">Ethereal Jaunt</a>:</td>
          <td> You become ethereal for 1 round/level.</td>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsHtoL.html#holy-word">Holy Word</a>:</td>
          <td> Kills, paralyzes, <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="abilitiesAndConditions.html#blinded">blinds</a>, or <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="abilitiesAndConditions.html#deafened">deafens</a> nongood subjects.</td>
        </tr>
        <tr>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsHtoL.html#mass-inflict-serious-wounds">Inflict
Serious Wounds, Mass</a>:</td>
          <td> Deals 3d8 damage +1/level to many creatures.</td>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsPtoR.html#refuge">Refuge</a><sup> M</sup>:</td>
          <td> Alters item to transport its possessor to you.</td>
        </tr>
        <tr>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsPtoR.html#regenerate">Regenerate</a>:</td>
          <td> Subject&rsquo;s severed limbs grow back, cures 4d8
damage
+1/level (max +35).</td>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsPtoR.html#repulsion">Repulsion</a>:</td>
          <td> Creatures can&rsquo;t approach you.</td>
        </tr>
        <tr>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsPtoR.html#greater-restoration">Restoration,
Greater</a> <sup>X</sup>:</td>
          <td> As restoration, plus restores all levels and ability
scores.</td>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsPtoR.html#resurrection">Resurrection</a><sup>
M</sup>:</td>
          <td> Fully restore <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="abilitiesAndConditions.html#dead">dead</a> subject.</td>
        </tr>
        <tr>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsS.html#greater-scrying">Scrying, Greater</a>:</td>
          <td> As scrying, but faster and longer.</td>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsS.html#summon-monster-vii">Summon
Monster VII</a>:</td>
          <td> Calls extraplanar creature to fight for you.</td>
        </tr>
        <tr>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsS.html#symbol-of-stunning">Symbol of
Stunning</a><sup> M</sup>:</td>
          <td> Triggered rune stuns nearby creatures.</td>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsS.html#symbol-of-weakness">Symbol of
Weakness</a><sup> M</sup>:</td>
          <td> Triggered rune weakens nearby creatures.</td>
        </tr>
        <tr>
          <td className="last-row"><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsTtoZ.html#word-of-chaos">Word
of Chaos</a>:</td>
          <td className="last-row"> Kills, confuses, stuns, or deafens
nonchaotic subjects.</td>
        </tr>
      </tbody>
    </table>
    <a id="eighth-level-cleric-spells"></a><h5><a id="eighth"></a>8TH-LEVEL CLERIC SPELLS</h5>
    <table className="spell-list" border="0" cellPadding="1" cellSpacing="0" style={{
      "width": "75%"
    }}>
      <tbody>
        <tr>
          <th style={{
            "width": "25%"
          }}>Spell Name</th>
          <th style={{
            "width": "75%"
          }}>Brief Description</th>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsAtoB.html#antimagic-field">Antimagic
Field</a>:</td>
          <td> Negates magic within 10 ft.</td>
        </tr>
        <tr>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsC.html#cloak-of-chaos">Cloak of Chaos</a><sup>
F</sup>:</td>
          <td> +4 to AC, +4 resistance, and SR 25 against lawful
spells.</td>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsC.html#create-greater-undead">Create
Greater Undead</a><sup> M</sup>:</td>
          <td> Create shadows, wraiths, spectres, or devourers.</td>
        </tr>
        <tr>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsC.html#mass-cure-critical-wounds">Cure
Critical Wounds, Mass</a>:</td>
          <td> Cures 4d8 damage +1/level for many creatures.</td>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsDtoE.html#dimensional-lock">Dimensional
Lock</a>:</td>
          <td> Teleportation and interplanar travel blocked for one
day/level.</td>
        </tr>
        <tr>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsDtoE.html#discern-location">Discern
Location</a>:</td>
          <td> Reveals exact location of creature or object.</td>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsDtoE.html#earthquake">Earthquake</a>:</td>
          <td> Intense tremor shakes 5-ft./level radius.</td>
        </tr>
        <tr>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsFtoG.html#fire-storm">Fire Storm</a>:</td>
          <td> Deals 1d6/level fire damage.</td>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsHtoL.html#holy-aura">Holy Aura</a><sup> F</sup>:</td>
          <td> +4 to AC, +4 resistance, and SR 25 against evil spells.</td>
        </tr>
        <tr>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsPtoR.html#greater-planar-ally">Planar
Ally, Greater</a> <sup>X</sup>:</td>
          <td> As lesser planar ally, but up to 18 HD.</td>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsHtoL.html#mass-inflict-critical-wounds">Inflict
Critical Wounds, Mass</a>:</td>
          <td> Deals 4d8 damage +1/level to many creatures.</td>
        </tr>
        <tr>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsS.html#shield-of-law">Shield of Law</a><sup>
F</sup>:</td>
          <td> +4 to AC, +4 resistance, and SR 25 against chaotic
spells.</td>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsS.html#greater-spell-immunity">Spell
Immunity, Greater</a>:</td>
          <td> As spell immunity, but up to 8th-level spells.</td>
        </tr>
        <tr>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsS.html#summon-monster-viii">Summon
Monster VIII</a>:</td>
          <td> Calls extraplanar creature to fight for you.</td>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsS.html#symbol-of-death">Symbol of Death</a><sup>
M</sup>:</td>
          <td> Triggered rune slays nearby creatures.</td>
        </tr>
        <tr>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsS.html#symbol-of-insanity">Symbol of
Insanity</a><sup> M</sup>:</td>
          <td> Triggered rune renders nearby creatures insane.</td>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsTtoZ.html#unholy-aura">Unholy Aura</a><sup>
F</sup>:</td>
          <td> +4 to AC, +4 resistance, and SR 25 against good spells.</td>
        </tr>
      </tbody>
    </table>
    <a id="ninth-level-cleric-spells"></a><h5><a id="ninth"></a>9TH-LEVEL CLERIC SPELLS</h5>
    <table className="spell-list" border="0" cellPadding="1" cellSpacing="0" style={{
      "width": "75%"
    }}>
      <tbody>
        <tr>
          <th style={{
            "width": "25%"
          }}>Spell Name</th>
          <th style={{
            "width": "75%"
          }}>Brief Description</th>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsAtoB.html#astral-projection">Astral
Projection</a><sup> M</sup>:</td>
          <td> Projects you and companions onto Astral Plane.</td>
        </tr>
        <tr>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsDtoE.html#energy-drain">Energy Drain</a>:</td>
          <td> Subject gains 2d4 negative levels.</td>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsDtoE.html#etherealness">Etherealness</a>:</td>
          <td> Travel to Ethereal Plane with companions.</td>
        </tr>
        <tr>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsFtoG.html#gate">Gate</a> <sup>X</sup>:</td>
          <td> Connects two planes for travel or summoning.</td>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsHtoL.html#mass-heal">Heal, Mass</a>:</td>
          <td> As heal, but with several subjects.</td>
        </tr>
        <tr>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsHtoL.html#implosion">Implosion</a>:</td>
          <td> Kills one creature/round.</td>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsMtoO.html#miracle">Miracle</a> <sup>X</sup>:</td>
          <td> Requests a deity&rsquo;s intercession.</td>
        </tr>
        <tr>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsS.html#soul-bind">Soul Bind</a><sup> F</sup>:</td>
          <td> Traps newly <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="abilitiesAndConditions.html#dead">dead</a> soul to prevent
resurrection.</td>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsS.html#storm-of-vengeance">Storm of
Vengeance</a>:</td>
          <td> Storm rains acid, lightning, and hail.</td>
        </tr>
        <tr>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsS.html#summon-monster-ix">Summon Monster
IX</a>:</td>
          <td> Calls extraplanar creature to fight for you.</td>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsTtoZ.html#true-resurrection">True
Resurrection</a><sup> M</sup>:</td>
          <td> As resurrection, plus remains aren&rsquo;t needed.</td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      